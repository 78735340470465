.podcast {
    width: 94%;
    height: auto;
    display: flex;
    color: white;
    margin: 3%;
    background-color: rgba(0,0,0,.5);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
  }

  .podcast .leftSide {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
  }

  .podcast .rightSide {

    height: 100%;
    width: 50%;
    margin-left: 3%;
    display: flex;
  }
  
  .podcastList {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  
  /* MENU ITEM STYLING */
  
  .podcastItem {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 3%;
    background-color: rgba(0,0,0,.5);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  }
  
.podcastTitle {
  display: flex;
  width:100%;
  flex-direction: row;
  justify-content: center;

}

#titleImage {
  width: 30%;
  height: 30%;
}

  .podcastItem a {
      text-decoration:none;
  }

  .podBut {
    width: 100%;
    margin: 3%;
  }
  
  .podcastItem div {
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .podcastItem h3 {
    margin-left: 20px;
    color: yellow;
  }
  .podcastItem p {
    margin-left: 20px;
    color: rgb(38, 195, 205);
  }
  
  @media only screen and (max-width: 1000px) { 
    .podcastItem {
      flex-direction: column;
      height: auto;
  
    }
    .podcastItem .leftSide {
      width: auto;
    }
    .podcastItem .rightSide {
      width: auto;
    }
  }