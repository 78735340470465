.about {
  width: 94%;
  height: auto;
  display: flex;
  margin: 3%;
  flex-direction: row;
  background-color: rgba(0,0,0,.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutLeft {
  display: flex;
  width: 50%;
  background-color: rgba(0,0,0,.3);
  flex-direction: column;
  height: 55%;
  align-items: center;
  margin: 3%;
  padding: 3%;
}

.aboutRight {
  width: 70%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: left;
  padding: 60px;
}

.about h1 {
  font-family: geneva;
}

/* .about h2 {
  color: crimson;
} */

@media only screen and (max-width: 850px) {
  .about {
    flex-direction: column;
  }
  .aboutLeft {
    width: 87%;
  }
}
