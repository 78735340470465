@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* background-color: black; */
  color: white;
  font-family: Rubix, Arial, Helvetica, sans-serif;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

body {
  padding: 0;
  margin: 0;
}

body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url(/static/media/fishphoto.d65b7af2.jpg) no-repeat center center;
  background-size: cover;
}


@media only screen and (max-width: 650px) { 
  .App {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    overflow: hidden;
    /* background-color: black; */
  }

  body:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    background: url(/static/media/fishCapture.d0c0d544.JPG) no-repeat center center;
    background-size: cover;
  }
}
.footer {
  width: 100%;
  height: 200px;
  background-color: #121619;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
}

.socialMedia svg {
  /* color: white; */
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
}

.socialMedia svg:hover {
  /* color: rgba(0, 0, 0, 0.3); */
  transition: 0.1s ease-in-out;
  cursor: pointer;
  -webkit-transform: scale(1.025);
          transform: scale(1.025); 
}

.footer p {
  color: white;
}

.home {
  width: 100%;
  height: 95vh;
  /* justify-content: center; */
  /* align-items: center; */
  /* display: flex; */
  justify-content: center;
  align-items: left;
  background-color: black;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

}

.headerContainer {
  width: auto;
  height: 97%;
  /* margin-left: 50px; */
  margin-bottom: 100px;
  /* margin-left: 0px; */
  border-radius: 10px;
  padding: 10px;
  /* width: 80%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0,0,0,.4);
  color: white;
}

.headerContainer h1 {
  font-size: 40px;
  height: 30px;
  color: white;
}

.headerContainer p {
  font-size: 20px;
  color: white;
}


/* .headerContainer button {

  background-color: #121619;
    list-style: none;
    margin: 0 15px;
    
}

.headerContainer button svg {
  position: relative; 
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 63px;
  background: #333;
  border-radius: 50%;
  font-size: 30px;
  color: #666;
  transition: .5s;
} */

.headerContainer button {
  margin-top: 40px;
  width: 140px;
  height: 40px;
  border: none;
  /* appearance: unset; */
  /* text-rendering: unset; */
  background-color: #1976D2;
  color: whitesmoke;
  font-size: 15px;
  cursor: pointer;
}

.headerContainer button:hover {
  /* color: rgba(0, 0, 0, 0.3); */
  transition: 0.1s ease-in-out;
  cursor: pointer;
  -webkit-transform: scale(1.025);
          transform: scale(1.025); 
}




  .image_wrapper {
    margin: 0px 30px;
  }
  
  .image_wrapper img {
    width: 100px;
  }


/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

@media only screen and (max-width: 400px) {
  .home {
    /* grid-template-columns: 1fr; */
    /* width: 100%; */
    /* height: 55vh; */
  }
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.about {
  width: 94%;
  height: auto;
  display: flex;
  margin: 3%;
  flex-direction: row;
  background-color: rgba(0,0,0,.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutLeft {
  display: flex;
  width: 50%;
  background-color: rgba(0,0,0,.3);
  flex-direction: column;
  height: 55%;
  align-items: center;
  margin: 3%;
  padding: 3%;
}

.aboutRight {
  width: 70%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: left;
  padding: 60px;
}

.about h1 {
  font-family: geneva;
}

/* .about h2 {
  color: crimson;
} */

@media only screen and (max-width: 850px) {
  .about {
    flex-direction: column;
  }
  .aboutLeft {
    width: 87%;
  }
}

.contact {
  width: 100%;
  height: auto;
  display: flex;
  color: white;
  /* background-image: url("../assets/lineArt1.jpg"); */
  /* background-color: rgba(0,0,0,.5); */
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* margin: 3% */
}

.contact .leftSide {
  height: auto;
  flex: 50% 1;
  margin: 5%;
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  display: flex;
  flex-direction: column;
  /* border-radius: 1000px; */
}

.contact .leftSide div{
  /* height: 40%; */
  /* flex: 10%; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
   display: flex;
  flex-direction: column;
  /* border-radius: 1000px;  */
 } 

.contact .rightSide {
  height: 100%;
  flex: 50% 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact .rightSide h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 10;
  font-size: 50px;
  margin-left: 30px;
}

form {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: auto;
  padding: 30px;
}

form input {
  height: 40px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}

form textarea {
  margin-top: 15px;
  height: 70px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-weight: bold;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}
input::placeholder,
textarea::placeholder {
  font-weight: bold;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}
form input:focus,
textarea:focus {
  outline: none;
}

form label {
  margin-top: 15px;
  color: grey;
}

.contact button {
  margin-top: 40px;
  width: 140px;
  height: 40px;
  border: none;
  /* appearance: unset; */
  /* text-rendering: unset; */
  background-color: #1976D2;
  color: whitesmoke;
  font-size: 15px;
  cursor: pointer;
}

.contact button:hover {
  /* color: rgba(0, 0, 0, 0.3); */
  transition: 0.1s ease-in-out;
  cursor: pointer;
  -webkit-transform: scale(1.025);
          transform: scale(1.025); 
}

.HoriParallax svg {
  /* position: relative;  */
  /* display: block; */
  width: 500px;
  height: 500px;
  text-align: center;
  line-height: 63px;
  /* background: #333; */
  border-radius: 50%;
  font-size: 30px;
  margin-left: 4%;
  color: #666;
  transition: .5s;
}

.HoriParallax svg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #ffee10;
  transition: .5s;
  -webkit-transform: scale(.9);
          transform: scale(.9);
  z-index: -1;
}

.HoriParallax svg:hover::before {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 0 0 15px #ffee10;
}

.contact p:hover,h1:hover,li:hover{
  /* background-color: #07090a; */
  /* cursor: pointer; */
  transition: 0.1s ease-in;

  color: #ffee10;
  box-shadow: 0 0 5px #ffee10;
  text-shadow: 0 0 5px #ffee10;
}


@media only screen and (max-width: 650px) { 
  .contact {
    flex-direction: column;
    height: auto;

  }
}
.banner {
    width: 100%;
    background-color: red;
    display: flex;
    justify-content: center;
}
.podcast{width:94%;height:auto;display:flex;color:#fff;margin:3%;background-color:rgba(0,0,0,.5);background-position:center;background-repeat:no-repeat;background-size:cover;flex-direction:column}.podcast .leftSide{height:100%;width:30%;display:flex;flex-direction:column}.podcast .rightSide{height:100%;width:50%;margin-left:3%;display:flex}.podcastList{width:90%;height:auto;display:flex;flex-direction:column}.podcastItem{width:100%;height:100%;display:flex;margin:3%;background-color:rgba(0,0,0,.5);box-shadow:0px 3px 15px rgba(0,0,0,.2)}.podcastTitle{display:flex;width:100%;flex-direction:row;justify-content:center}#titleImage{width:30%;height:30%}.podcastItem a{text-decoration:none}.podBut{width:100%;margin:3%}.podcastItem div{width:100%;height:200px;background-position:center;background-repeat:no-repeat;background-size:cover}.podcastItem h3{margin-left:20px;color:#ff0}.podcastItem p{margin-left:20px;color:#26c3cd}@media only screen and (max-width: 1000px){.podcastItem{flex-direction:column;height:auto}.podcastItem .leftSide{width:auto}.podcastItem .rightSide{width:auto}}
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* body {
  font-family: "Poppins", sans-serif;
} */
.theme{
  display: flex;
  /* color: #AF8ED5; */
  align-items: center;
  justify-content: center;
  /* width: 200px; */
  /* left: 600px; */
}
.theme div {
  display: flex;
  color: #AF8ED5;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */

}

.active2{
  height: 4rem;
  /* width: 100%; */
  /* position: fixed; */
  top: 0px;
  transition: 0.3s linear;
  display: flex;
  justify-content:stretch;
  align-items: center;
  background-color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  /* z-index: 40; */
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
  /* padding: 0 7%; */
}

.hidden2{
  height: 4rem;
  width: 100%;
  z-index: 40;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
  /* position: fixed; */
  /* top: -80px; */
  top: -160px;
  transition: 0.3s linear;

}


.container {
  max-width: 1050px;
  width: 90%;
  margin: auto;
}

.navbar2 {
  width: 100%;
  /* position: fixed; */
  top: 0;
  z-index: 6;
  /* transition-timing-function: ease-in; */
  /* transition: 0.5s; */
  box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
}

.nav-container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  height: 62px;
}

.navbar2 .menu-items {
  display: flex;
}

.navbar2 .nav-container li {
  list-style: none;
}

.navbar2 .nav-container a {
  text-decoration: none;
  color: #0e2431;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.7rem;
}

.navbar2 .nav-container a:hover{
    font-weight: bolder;
}

.nav-container {
  display: block;
  /* position: relative; */
  height: 60px;
}

.nav-container .checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 20px;
  right: 60px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.nav-container .hamburger-lines {
  display: block;
  height: 26px;
  width: 32px;
  position: absolute;
  top: 17px;
  right: 60px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  /* background: #0e2431; */
  background: #4ea8de;
}

.nav-container .hamburger-lines .line1 {
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.navbar2 .menu-items {
  padding-top: 120px;
  box-shadow: inset 0 0 2000px #0e2431;
  background-color: rgba(255, 255, 255, .9);
  height: 100vh;
  width: 100%;
  -webkit-transform: translate(-150%);
          transform: translate(-150%);
  display: flex;
  flex-direction: column;
  margin-left: -40px;
  padding-left: 50px;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  text-align: center;
}

.navbar2 .menu-items li {
  margin-bottom: 1.2rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.navbar2 .logo {
  position: absolute;
  top: 0px;
  left: 15px;
  font-size: 1.2rem;
  width: 25%;
  background-color: #000;
  /* height: 50px; */
  /* height: 100%; */
  /* color: #0e2431; */
}

@media only screen and (max-width: 600px) {
  .navbar2 .menu-items {
    box-shadow: inset 0 0 100px #0e2431;

  }

  
}

.nav-container input[type="checkbox"]:checked ~ .menu-items {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.nav-container input[type="checkbox"]:checked ~ .logo{
  display: none;
}
/* Inspired by this pen by Pieter Biesemans https://codepen.io/pieter-biesemans/pen/BQBWXX and My Mother Before Me project http://mymotherbeforeme.com/ */

.HoriParallax {
    font-family: Hepta Slab, sans-serif;
    font-weight: 500;
    /* background: #efecdb; */
    color: #5D4037;
  }
  /* * {
    box-sizing: border-box;
  } */
  
  /* hide scrollbar */
  ::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }
  
  ::-webkit-scrollbar-button {
    width: 1px;
    height: 1px;
  }
  .external {
    overflow: hidden;
    height: 100vh;
  }
  
  .horizontal-scroll-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 83vh;
    -webkit-transform: rotate(-90deg) translate3d(0,-100vh,0);
            transform: rotate(-90deg) translate3d(0,-100vh,0);
    -webkit-transform-origin: right top;
            transform-origin: right top;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    height: 100vw;
    -webkit-perspective: 1px;
            perspective: 1px;
      -webkit-transform-style: preserve-3d;
              transform-style: preserve-3d;
    padding-bottom: 10rem;
  }
  .img-wrapper {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: rotate(90deg) translateZ(.1px) scale(0.9) translateX(0px) translateY(-3vh);
            transform: rotate(90deg) translateZ(.1px) scale(0.9) translateX(0px) translateY(-3vh);
    transition: 1s;
  }
  
  .img-wrapper:hover {
   min-height: 65vh;
  }
  
  .slower {
    -webkit-transform: rotate(90deg) translateZ(-.2px) scale(1.1) translateX(0%) translateY(-10vh);
            transform: rotate(90deg) translateZ(-.2px) scale(1.1) translateX(0%) translateY(-10vh);
  }
  .slower1 {
    -webkit-transform: rotate(90deg) translateZ(-.25px) scale(1.05) translateX(0%) translateY(8vh);
            transform: rotate(90deg) translateZ(-.25px) scale(1.05) translateX(0%) translateY(8vh);
  }
  .slower2 {
    -webkit-transform: rotate(90deg) translateZ(-.3px) scale(1.3) translateX(0%) translateY(2vh);
            transform: rotate(90deg) translateZ(-.3px) scale(1.3) translateX(0%) translateY(2vh);
  }
  .slower-down {
    -webkit-transform: rotate(90deg) translateZ(-.2px) scale(1.1) translateX(0%) translateY(16vh) ;
            transform: rotate(90deg) translateZ(-.2px) scale(1.1) translateX(0%) translateY(16vh) ;
  }
  .faster {
    -webkit-transform: rotate(90deg) translateZ(.15px) scale(0.8) translateX(0%) translateY(14vh) ;
            transform: rotate(90deg) translateZ(.15px) scale(0.8) translateX(0%) translateY(14vh) ;
  }
  .faster1 {
    -webkit-transform: rotate(90deg) translateZ(.05px) scale(0.8) translateX(0%) translateY(10vh) ;
            transform: rotate(90deg) translateZ(.05px) scale(0.8) translateX(0%) translateY(10vh) ;
  }
  .fastest {
    -webkit-transform: rotate(90deg) translateZ(.22px) scale(0.7) translateX(-10vh) translateY(-15vh) ;
            transform: rotate(90deg) translateZ(.22px) scale(0.7) translateX(-10vh) translateY(-15vh) ;
  }
  .vertical {
     -webkit-transform: rotate(90deg) translateZ(-.15px) scale(1.15) translateX(0%) translateY(0%) ;
             transform: rotate(90deg) translateZ(-.15px) scale(1.15) translateX(0%) translateY(0%) ;
  }
  .last {
    -webkit-transform: rotate(90deg) translateZ(-.2px) scale(1.1) translateX(25vh) translateY(-8vh) ;
            transform: rotate(90deg) translateZ(-.2px) scale(1.1) translateX(25vh) translateY(-8vh) ;
  }
  .scroll-info, header {
    position: absolute;
    left: 1rem;
  }
  
  
  header {
    /* bottom: 1rem; */
  }
  .scroll-info {
    top: 1rem;
  }
  .HoriParallax a {
    color: inherit;
    font-weight: 500;
    
  }
  .HoriParallax h1 {
    font-weight: 300;
    font-size: 1rem;
  }
  
  .img-wrapper a {
    overflow: hidden;
    display: block;
    padding: 1vh ;
    background: #efecdb;
    box-shadow: 0 10px 50px #5f2f1182;
  }
  .HoriParallax img {
    max-width: 45vh;
    max-height: 50vh;
    transition: .5s;
    vertical-align: top;
    -webkit-filter: saturate(40%) sepia(30%) hue-rotate(5deg);
            filter: saturate(40%) sepia(30%) hue-rotate(5deg);
  }
  .HoriParallax a:hover img {
    -webkit-filter: none;
            filter: none;
  }
  .HoriParallax p {
    margin: 0;
  }
  .scroll-info {
    display: flex;
    align-items: center;
  }
  /* .icon svg {
      width: 50px;
      fill: currentcolor;
  } */

  .HoriParallax svg {
    /* position: relative;  */
    /* display: block; */
    width: 500px;
    height: 500px;
    text-align: center;
    line-height: 63px;
    /* background: #333; */
    border-radius: 50%;
    font-size: 30px;
    margin-left: 4%;
    color: #666;
    transition: .5s;
  }
  
  .HoriParallax svg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #ffee10;
    transition: .5s;
    -webkit-transform: scale(.9);
            transform: scale(.9);
    z-index: -1;
  }
  
  .HoriParallax svg:hover::before {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0 15px #ffee10;
  }
  
  .HoriParallax svg:hover, .communityRight img:hover {
    /* background-color: #07090a; */
    /* cursor: pointer; */
    transition: 0.3s ease-in;
  
    color: #ffee10;
    box-shadow: 0 0 5px #ffee10;
    text-shadow: 0 0 5px #ffee10;
  }
