.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* background-color: black; */
  color: white;
  font-family: Rubix, Arial, Helvetica, sans-serif;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

body {
  padding: 0;
  margin: 0;
}

body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url("./assets/fishphoto.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


@media only screen and (max-width: 650px) { 
  .App {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    overflow: hidden;
    /* background-color: black; */
  }

  body:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    background: url("./assets/fishCapture.JPG") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}